<template>
  <div class="hello">
    <div v-if="isMobiles">
      <vs-row>
        
        <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
          <div style="position: fixed; width: 100%; z-index: 1000;">
            <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
            <div style="width: 400px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
              <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 16px;"></vs-icon> SOGECIT /</span> EIR RETOUR
            </div>
            </vs-card>
          </div>
          
          <el-input style="margin: 100px; margin-left:5%; width: 90%" placeholder="Numéro Conteneur" v-model="eirdatas.conteneur" class="input-with-select">
            <el-button slot="append" @click="searchEir()" icon="el-icon-search"></el-button>
          </el-input>

          <div style="margin-top: -100px; padding: 15px">
            <vs-divider/>
            <p style="text-align: center"><b style="font-size: 20px; letter-spacing: 2px; color: blue">{{eirdata.n_conteneur}}</b></p>
            <p style="text-align: center"><b style="font-size: 14px; letter-spacing: 2px; color: gray">{{eirdata.bl}}</b></p>
            <vs-divider/>
            <p>Client : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.client}}</b></p>
            <p>Armateur : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.armateur}}</b></p>
            <p>Lieu de livraison : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.zone_livraison}} / {{eirdata.mode}} / {{eirdata.type_c}}'</b></p>
            <p>Chauffeur : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.chauffeur}}</b></p>
            <p>Camion : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.camion}}</b></p>
            <p>Sortie terminal : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.sortie_port}}</b></p>
            <p>Entrée terminal : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.date_recup}}</b></p>
            <p>Contact : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.observation}}</b></p>
            <input v-if="chargeeir == true" @change="uploadImage($event, 'a')" type="file" placeholder="Choisir le fichier" required ref="file" name="image" id="uploadInput" class="form-control">
                      <vs-progress indeterminate color="primary" v-show="chargeFichier == 1">primary</vs-progress>
                      <vs-progress :percent="100" color="primary" v-show="chargeFichier == 2">primary</vs-progress>
                      <span v-show="chargeFichier == 2"><i class="el-icon-circle-check"></i> Eir Succès</span>
                      <span v-if="eirdata.eir.length < 10"><vs-button color="primary" type="flat" v-if="chargeeir == false" style="font-size: 20px" @click="chargeeir=  true" >Ajouter EIR</vs-button></span>
            <vs-divider/>
            <p style="color: red" v-if="eirdata.sortie_port < 5">BADT : <b style="font-size: 17px; letter-spacing: 2px;">{{eirdata.badt}}</b></p>
            <a target="_blank" v-if="eirdata.eir.length > 10" :href="eirdata.eir"><el-button type="primary">Télécharger EIR</el-button></a>
          </div>

         
        </vs-col>
      </vs-row>
    </div>
      <div v-else class="screenUse">
        <Nav-bar style="width: 100%;"/>
        <vs-row>
          <vs-col vs-w="2">
            <Side-bar style="width: 100%;"/>
          </vs-col>
          <vs-col vs-w="10" style="overflow:scroll; height: calc(100vh - 48px);">
            <div style="position: fixed; width: calc(79% - 13px); z-index: 1000;">
              <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
              <div style="width: 7500px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 18px;"></vs-icon> Administration /</span> Dashboard (Bennes)
                <el-date-picker
                  v-model="dateSearch"
                  type="daterange"
                  range-separator="à"
                  start-placeholder="Date de début"
                  end-placeholder="Date de fin">
                </el-date-picker>
                <el-button icon="el-icon-search" circle @click="searchDate()"></el-button>
              </div>
             
              </vs-card>
            </div>
            
            <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 70px;">

              
              <vs-row>

                <vs-col vs-w="3" style="padding-right: 10px;">
                    <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 3px solid #f0c1c5;">
                      <vs-icon icon="local_shipping" size="small" bg="#f0c1c5" style="color: #c22d39; font-size: 20px;" round></vs-icon> <span class="titre-zone">Total Voyages</span>
                          <vs-row class="space-zone">
                            <vs-col vs-w="12" style="display: grid;">
                              <vs-row class="space-zone">
                                <vs-col vs-w="5.5" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="local_shipping" color="white" bg="#409eff" round></vs-icon> <span class="prix-zone">{{ totalVoyages }} <span style="font-weight: normal;">voyages</span></span></span>
                                </vs-col>
                                <vs-col vs-w="6.5" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone">0 CFA</span></span>
                                </vs-col>
                              </vs-row>
                            </vs-col>
                          </vs-row>
                    </el-card>
                  </vs-col>

                  <vs-col vs-w="3" style="padding-right: 10px;">
                    <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 3px solid #13ce66;">
                      <vs-icon icon="filter_alt" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone" v-if="donneesStats.dn_carb[0].total !== null && donneesStats.dn_carb[0].total !== undefined">Total Carburants ( {{ donneesStats.dn_carb[0].total }} )</span>
                         <vs-row class="space-zone">
                            <vs-col vs-w="12" style="display: grid;">
                              <vs-row class="space-zone">
                                <vs-col vs-w="5.5" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="filter_alt" color="white" bg="#13ce66" round></vs-icon> <span class="prix-zone" v-if="donneesStats.dn_dep[0].litre !== null && donneesStats.dn_dep[0].litre !== undefined">{{ donneesStats.dn_carb[0].litre }} <span style="font-weight: normal;">litres</span></span></span>
                                </vs-col>
                                <vs-col vs-w="6.5" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone" v-if="donneesStats.dn_carb[0].prix !== null && donneesStats.dn_carb[0].prix !== undefined">{{ formatMoney(donneesStats.dn_carb[0].prix) }}</span></span>
                                </vs-col>
                              </vs-row>
                            </vs-col>
                          </vs-row>
                    </el-card>
                  </vs-col>

                  <vs-col vs-w="3" style="padding-right: 10px;">
                    <router-link to="/camion_mobile">
                      <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 3px solid #009eff5c;">
                      <vs-icon icon="payments" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone">Total Dépenses</span>
                         <vs-row class="space-zone">
                            <vs-col vs-w="12" style="display: grid;">
                              <vs-row class="space-zone">
                                <vs-col vs-w="5.5" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#640064" round></vs-icon> <span class="prix-zone" v-if="donneesStats.dn_dep[0].total !== null && donneesStats.dn_dep[0].total !== undefined">{{ donneesStats.dn_dep[0].total }} <span style="font-weight: normal;">dépenses</span></span></span>
                                </vs-col>
                                <vs-col vs-w="6" style="display: grid;">
                                  <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone" v-if="donneesStats.dn_dep[0].prix !== null && donneesStats.dn_dep[0].prix !== undefined">{{ formatMoney(donneesStats.dn_dep[0].prix) }}</span></span>
                                </vs-col>
                              </vs-row>
                            </vs-col>
                          </vs-row>
                    </el-card>
                  </router-link>
                  </vs-col>
                </vs-row>

                <vs-divider position="left">.</vs-divider>
                <div style="background-color: white;">
                  <vs-row>
                    <vs-col vs-w="7" style="display: grid;">
                      <div style="border: 1px solid #8080802e; margin: 10px; padding: 15px;">
                        <h3>Bilan Camion</h3>

                        <el-card
                            class="box-card"
                            style="border-left: 3px solid gray; height: 80px; width: 100%; margin-top: 8px"
                            v-for="item in don_Camions"
                            :key="item.produit"
                          >
                            <vs-row>
                              <vs-col vs-w="1.5" style="display: grid;">
                                <span><vs-avatar icon="local_shipping" /></span>
                              </vs-col>
                              <vs-col vs-w="5" style="display: grid;">
                                <b style="letter-spacing: 2px; font-size: 18px">{{ item.camion }}</b>
                              </vs-col>
                              <vs-col vs-w="4" style="display: grid; text-align: left">
                                <p><b>{{ item.nb }}</b> Voyage(s)</p>
                                <p><b style="letter-spacing: 2px">{{ item.litre_total.toFixed(0) }}</b> Libre(s)</p>
                              </vs-col>
                              <vs-col vs-w="1" style="display: grid; text-align: center">
                                <center><vs-button color="primary" type="border" icon="search"></vs-button></center>
                              </vs-col>
                            </vs-row>
                          </el-card>

                       
                      </div>
                    </vs-col>
                    <vs-col vs-w="5" style="display: grid;">
                      <div style="border: 1px solid #8080802e; margin: 10px; padding: 15px;" v-show="counter">
                        <h3>Bilan Client 6666</h3>
                        <el-table
                          :data="don_Clients2"
                          stripe
                          style="width: 100%; font-size: 13px;">
                          <el-table-column
                            prop="date"
                            label="CLIENT">
                            <template slot-scope="scope">
                              <vs-chip transparent color="primary">
                                <b>{{ scope.row.client }}</b>
                              </vs-chip>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="name"
                            label="PROD">
                            <template slot-scope="scope">
                                <b style="font-size: 11px;">{{ scope.row.produit }}</b>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="BONS">
                            <template slot-scope="scope">
                              <vs-chip color="success">
                                <b style="color: black; letter-spacing: 2px; font-size: 13px;">{{ scope.row.total_enregistrements }}</b>
                              </vs-chip>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="POIDS">
                            <template slot-scope="scope">
                                <b>{{ scope.row.total_poids.toFixed(2) }}</b> T
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="ACTION">
                            <template slot-scope="scope">
                              <vs-button color="primary" type="flat" @click="showClient(scope.row)">Détails</vs-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div style="border: 1px solid #8080802e; margin: 10px; padding: 15px;">
                        <h3>Bilan Client</h3>
                        <div style="width: 100%">
                          <el-card
                            class="box-card"
                            :style="'border-left: 10px solid ' + getClientColor(item.client)+ '; height: 80px; width: 100%; margin-top: 8px'"
                            v-for="item in don_Clients"
                            :key="item.produit"
                          >
                            <vs-row>
                              <vs-col vs-w="1.5" style="display: grid;">
                                <span><vs-avatar icon="local_shipping" /></span>
                              </vs-col>
                              <vs-col vs-w="5" style="display: grid;">
                                <b :style="{ color: getClientColor(item.client) }">{{ item.produit }}</b> {{ item.client }}
                              </vs-col>
                              <vs-col vs-w="4" style="display: grid; text-align: left">
                                <p><b>{{ item.total_enregistrements }}</b> Bons</p>
                                <p><b style="letter-spacing: 2px">{{ item.total_poids.toFixed(2) }}</b> Tonnes</p>
                              </vs-col>
                              <vs-col vs-w="1" style="display: grid; text-align: center">
                                <center><vs-button color="primary" type="border" icon="search"></vs-button></center>
                              </vs-col>
                            </vs-row>
                          </el-card>
                        </div>
                        <br>
                       
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
                

              
              <vs-divider/>
              
            </div>

          </vs-col>
        </vs-row>

        <vs-popup classContent="popup-example"  title="BILAN CLIENT" :active.sync="popupActivo2">
          <div>
            <vs-row>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Client</label>
                {{ ClientChoisi.client }}
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Produit</label>
                {{ ClientChoisi.produit }}
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Bons</label>
                {{ ClientChoisi.total_enregistrements }}
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Poids</label>
                {{ ClientChoisi.total_poids }}
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Prix Unitaire</label>
                <el-input style="width: 100%;" v-model="prixUnit" placeholder="Prix">
                  </el-input>
              </vs-col>
              <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                <label>Prix total</label>
                {{ ClientChoisi.total_poids * prixUnit }}
              </vs-col>
            </vs-row>
            
          </div>
         
          
          <center><vs-button style="margin: 20px; width: 300px;" @click="generateReport()" color="primary" type="filled">Enregistrer le dossier</vs-button></center>
          <vs-popup title="Inner popup" :active.sync="popupActivo3">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              <br><br>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <br><br>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            </vs-popup>
        </vs-popup>

        <vs-popup classContent="popup-example"  title="Bordereau de livraison" :active.sync="activePrompt9">
          
          <center style="width: 21cm;">
            
            <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="800"
            filename="hee hee"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="PORTRAIT"
            pdf-content-width="800px"

            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
              <div>
                <div style="width: 28cm;  margin-left: .5cm; margin-top: 20px;" id="html-to-pdf">
                  <div style="width: 100%; padding: 10px">
                    <div style="float: left; display: block;">
                    </div>
                    <div style="float: left; padding: 12px;">
                      <span style="font-weight: bold; font-size: 19px; font-family: 'Nunito Sans', sans-serif;">BILAN MENSUEL - <span><b> </b></span><span style="color: red; font-size: 18px"></span></span>
                    </div>
                  </div>
                .
                  <h2></h2>
                  <vs-divider/>
                  <vs-row>
                    <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="3">
                      <el-input placeholder="Entrez quelque chose"  v-model="ClientChoisi.client">
                        <template slot="prepend">CLIENT</template>
                      </el-input>
                    </vs-col>
                    <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="5">
                      <el-input placeholder="Entrez quelque chose" v-model="ClientChoisi.produit">
                        <template slot="prepend">PRODUIT</template>
                      </el-input>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="3">
                      <el-input placeholder="Entrez quelque chose"  v-model="ClientChoisi.total_enregistrements">
                        <template slot="prepend">Bons / Voyages</template>
                      </el-input>
                    </vs-col>
                    <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="5">
                      <el-input placeholder="Entrez quelque chose" v-model="ClientChoisi.total_poids">
                        <template slot="prepend">Poids</template>
                      </el-input>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="3">
                      <el-input placeholder="Entrez quelque chose"  v-model="prixUnit">
                        <template slot="prepend">Prix Unitaire</template>
                      </el-input>
                    </vs-col>
                    <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="5">
                      <el-input placeholder="Entrez quelque chose" :value="ClientChoisi.total_poids * prixUnit">
                        <template slot="prepend">Prix Total</template>
                      </el-input>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
                
            </section>
        </vue-html2pdf>
           
                  
          </center>
                
         
        </vs-popup>
      </div>
     
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from "html2pdf.js";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import axios from 'axios';
import SideBar from '@/components/bennes/Benne_side.vue'
import NavBar from '@/components/Navbar.vue'
export default {
  name: 'HelloWorld',
  components: {
    SideBar,
    NavBar,
    VueHtml2pdf
  },
  data() {
    return {
      isMobiles : isMobile,
      counter: false,
      chargeeir: false,
      prixUnit: 0,
      dateSearch: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10)
        ],
        eirdata: {
        client: '',
        eir: ''
      },
      eirdatas: {
        conteneur: '',
        eir: ''
      },
      popupActivo2:false,
      popupActivo3:false,
      activePrompt9:false,
      select1:3,
      options1:[
        {text:'IT',value:0},
        {text:'Blade Runner',value:2},
        {text:'Thor Ragnarok',value:3},
      ],
      formDate: {
        dateBen: ''
      },
      checkTrue: true,
      donneesClient: null,
      donneesCliProd: null,
      checkFalse: false,
      totalVoyages:0,
      donnees: null,
      don_camions: [{"nb":"0","litre_total":"0","camion":"0"}],
      don_Clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
      don_Clients2: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
      don_Camion: null,
      donneesStats: {
        dn_carb: [{"total":"0","litre":"0","prix":"0"}],
        dn_dep: [{"total":"0","prix":"0"}],
        dn_voyages: [{"chauffeur":"","client":"","dossier":"0","id_ben":"0","lieu_chargement":"0","lieu_dechargement":"","n_bl":"","n_matricule":"","observation":"0","par":"0","poids":"0","prix":"0","produit":"0"}],
        dn_clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        nb_carb: 0,
        nb_dep: 0,
        nb_voyages: 0,
        tableau: [{"camion":"0","chauffeur":"0","date_carb":"0","details":"0","id_camion":"0","id_carburant":"0","imatriculation":"0","litre":"0","marque":"0","nombre_de_bennes":"0","prix":"0"}],
      },
      donneesStatsMois: null,
      donneesStatsSemaine: null,
      elementChoisi: null,
      donneesZones: null,
      donneesCamion: null,
      ClientChoisi: {
        client: '',
        produit: '',
        total_enregistrements: '',
        total_poids: ''
      },
      donneesChauf: null,
      deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
      like: true,
        value1: '',
        value2: '',
        title: 'Growth this year',
      activeName: 'first',
      chartData: {
        labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
        datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
      },
      chartOptions: {
        responsive: true
      },
      form: {
        date_recept: '',
        n_doc: '',
        n_conteneur: '',
        type_c: '',
        nbre_tcs: 1,
        n_declaration: '',
        bl: '',
        statut: '',
        armateur: '',
        client: '',
        camion: '',
        chauffeur: '',
        zone_livraison: '',
        zone_facturation: '',
        badt: '',
        do: '',
        sortie_port: '',
        sejour: '',
        date_requete: '',
        date_recup: '',
        entree_parc: '',
        transitaire: '',
        observation: ''
        },
        options: [{
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }],
        type_cont: [{
          value: "20 'OPEN",
          label: "20 'OPEN"
        }, {
          value: "40 'OPEN",
          label: "40 'OPEN"
        }, {
          value: "20 'FLAT",
          label: "20 'FLAT"
        },{
          value: "40 'FLAT",
          label: "40 'FLAT"
        }, {
          value: "20 'DRY",
          label: "20 'DRY"
        }, {
          value: "40 'DRY",
          label: "40 'DRY"
        }, {
          value: "20 'FRIGO",
          label: "20 'FRIGO"
        }, {
          value: "40 'FRIGO",
          label: "40 'FRIGO"
        }],
        armateur: [{
          value: "Evergreen",
          label: "Evergreen"
        }, {
          value: "Maersk",
          label: "Maersk"
        }, {
          value: "Safmarine",
          label: "Safmarine"
        },{
          value: "Cosco",
          label: "Cosco"
        }, {
          value: "One",
          label: "One"
        }, {
          value: "PIL",
          label: "PIL"
        }, {
          value: "Oma",
          label: "Oma"
        }, {
          value: "Medlog",
          label: "Medlog"
        }],
      tableData: [{
          date: '2016-05-03',
          name: 'TRAORE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }, {
          date: '2016-05-02',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'Validé'
        }, {
          date: '2016-05-04',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }, {
          date: '2016-05-01',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'Annulé'
        }, {
          date: '2016-05-08',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'Validé'
        }, {
          date: '2016-05-06',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }, {
          date: '2016-05-07',
          name: 'SAKONE Oumar',
          address: 'No. 189, Grove St, Los Angeles',
          st: 'En cours'
        }],
    }
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    searchEir() {
      this.chargeeir = false;
      this.$vs.loading({
          type:'point',
          })
          axios.get('https://sogecit.net/appi_socogetra/cherchetc.php?tc='+this.eirdatas.conteneur+'&timestamp=' + new Date().getTime())
          .then((response) => {
            this.eirdata = response.data.data[0];
            this.$vs.loading.close()
          }, (error) => {
            console.log(error);
            this.$vs.loading.close()
          });
    },

    uploadImage(e, a) {
            if(a === 'a') {
              this.chargeFichier = 1;
            }
            if(a === 'b') {
              this.chargeFichier2 = 1;
            }
            if(a === 'c') {
              this.chargeFichier3 = 1;
            }
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image = files[0]; 

            let formData = new FormData();
            let uploadPreset = "dsegjq9c"
            let cloudinaryUrl = "https://api.cloudinary.com/v1_1/r-sidence-meubl-e/upload"
            this.annee = this.printYear();
            formData.append('file', this.image);
            formData.append('public_id', this.annee);
            formData.append('upload_preset', uploadPreset)
            axios.post(cloudinaryUrl, formData)
            .then(res => {
                if(a === 'a') {
                  this.chargeFichier = 2;
                  let fichierUrl = res.data;
                  this.eirdatas.eir = fichierUrl.url;
                  console.log(this.eirdatas);
                  this.posteir();
                  
                }
                if(a === 'b') {
                  this.chargeFichier2 = 2;
                  let fichierUrl = res.data;
                  this.form.fichier_piece = fichierUrl.url;
                }
                if(a === 'c') {
                  this.chargeFichier3 = 2;
                  this.form.email = null;
                  this.form.email = res.data.url;
                }
            })
            .catch(err => {
                console.log(err)
            })
        },

        printYear: function () {
            return new Date().getFullYear() + 
            '' + new Date().getMonth()+ 
            '' + new Date().getDate()+
            '' + new Date().getHours()+
            '' + new Date().getMinutes()+
            '' + new Date().getMilliseconds()+
            '' + new Date().getSeconds();
      },

    posteir() {
      axios.post('https://sogecit.net/appi_socogetra/cherchetc.php', this.eirdatas)
            .then((response) => {
                console.log(response);
                this.popupActivo2 = false,
                this.$vs.loading.close();
                this.$notify.success({
                title: 'EIR OK!',
                message: '',
                showClose: false
                });
               // const lala = this.eirdatas.conteneur;
                this.chargeeir = false;
                this.eirdatas.eir = "";
                this.searchEir();
            })
            .catch(function (error) {
                console.log(error);
                this.$vs.loading.close()
            });
    },
    getClientColor(client) {
    // Générer une couleur en fonction du nom du client
    const color = this.stringToColor(client);
    return color;
  },
  stringToColor(str) {
    // Génère une couleur unique en fonction de la chaîne d'entrée
    // Vous pouvez implémenter une logique de hachage ici
    // Exemple simple : convertir la chaîne en une couleur RVB aléatoire
    const hash = str.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const r = (hash * 17) % 256;
    const g = (hash * 13) % 256;
    const b = (hash * 19) % 256;
    return `rgb(${r},${g},${b})`;
  },

    choixPeriode(a) {
      this.periode = a;
      console.log(this.periode);
    },

    listeZones2 () {
          
          axios.get('https://socogetra.net/appi_sococit/albakoos_produits_clients.php?timestamp=' + new Date().getTime())
          .then((response) => {
            this.donneesClient = response.data.data;
            this.$vs.loading.close()
            this.filtreUser();
          }, (error) => {
            console.log(error);
            this.$vs.loading.close()
          });
      },

      Stats() {
            this.$vs.loading({
              type: 'point',
            });
            axios.get('https://socogetra.net/appi_sococit/albakoos_stats.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.totalVoyages = 0;
              this.donneesStats = response.data;
              this.don_Clients = response.data.dn_clients;
              this.don_camions = response.data.camion_litre;
              this.don_Clients.forEach((item) => {
                this.totalVoyages = this.totalVoyages + parseInt(item.total_enregistrements);
              })
              console.log(this.don_Clients);
              this.$vs.loading.close();
            }, (error) => {
              console.log(error);
              this.$vs.loading.close();
            });
          },

      searchDate () {
          
          this.$vs.loading({
          type:'point',
          })
          const year = this.dateSearch[0].getFullYear();
          const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
          const day = String(this.dateSearch[0].getDate()).padStart(2, '0');

          const year2 = this.dateSearch[1].getFullYear();
          const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
          const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');

          let dte_db = `${year}-${month}-${day}`;
          let dte_fn = `${year2}-${month2}-${day2}`;

          fetch('https://socogetra.net/appi_sococit/albakoos_produits_clients_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
          .then(response => response.json())
          .then(data => {
            this.donneesClient = data.data;
            this.$vs.loading.close()
          });

          fetch('https://socogetra.net/appi_sococit/albakoos_stats_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
          .then(response => response.json())
          .then(data => {
            this.don_camions = data.data.camion_litre;
            this.don_Clients = data.data.dn_clients;
            this.$vs.loading.close()
          });

          axios
              .get('https://socogetra.net/appi_sococit/albakoos_stats_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
              .then(response => (this.don_camions = response.data.camion_litre))

          axios
              .get('https://socogetra.net/appi_sococit/albakoos_stats_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
              .then(response => (this.don_Clients = response.data.dn_clients))
          
          

      },

      StatsMonth () {
          this.$vs.loading({
          type:'point',
          })
          axios.get('https://socogetra.net/appi_sococit/albakoos_stats_month.php?timestamp=' + new Date().getTime())
          .then((response) => {
            this.donneesStatsMois = response.data;
            this.$vs.loading.close()
          }, (error) => {
            console.log(error);
            this.$vs.loading.close()
          });
      },

      StatsWeek () {
          this.$vs.loading({
          type:'point',
          })
          axios.get('https://socogetra.net/appi_sococit/Albakoos_stats_week.php?timestamp=' + new Date().getTime())
          .then((response) => {
            this.donneesStatsSemaine = response.data;
            this.$vs.loading.close()
          }, (error) => {
            console.log(error);
            this.$vs.loading.close()
          });
      },
      

      listeDossier () {
          this.$vs.loading({
          type:'point',
          })
          axios.get('https://socogetra.net/appi_sococit/albakoos_dossier_sbtl.php?timestamp=' + new Date().getTime())
          .then((response) => {
            this.donnees = response.data.data;
            this.$vs.loading.close()
          }, (error) => {
            console.log(error);
            this.$vs.loading.close()
          });
      },

      formatMoney(value) {
        const numberValue = parseFloat(value.replace(/ /g, '').replace(/,/g, '.'));
        if (isNaN(numberValue)) {
          return '';
        }
        return numberValue.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        });
      },

      listeZones () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_zones.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesZones = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        showClient(client) {
          this.ClientChoisi = client;
          console.log(this.ClientChoisi);
          this.popupActivo2 = true
        },

        generateReport () {
          this.$refs.html2Pdf.generatePdf()
          this.popupActivo2 = false;
          this.activePrompt9 = true;
        },

        exportToPDF() {
          html2pdf(document.getElementById("html-to-pdf"), {
            margin: 1,
            filename: "i-was-html.pdf",
          });
        },

        listeChauffeurs () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_chauffeurs.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesChauf = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        Borderau(element) {
          this.$router.push({ name: 'camion_mobile', params: { element: element } });
        },

        generatePDF () {
          var doc = new jsPDF();
          
          // Récupérer l'élément HTML que vous voulez convertir en PDF
          const element = document.getElementById('html-to-pdf')

          // Convertir l'élément HTML en Canvas
          html2canvas(element).then(canvas => {
            // Ajouter le Canvas au document PDF avec une résolution de 300 DPI
            const imgData = canvas.toDataURL('image/png', 2.7);
            doc.addImage(imgData, 'PNG', 10, 10);
            
            // Sauvegarder le document PDF
            doc.save('example.pdf')
          })
        },

       // generatePDF () {
        //  var doc = new jsPDF();

          // Récupérer l'élément HTML que vous voulez convertir en PDF
        //  const element = document.getElementById('html-to-pdf')

          // Convertir l'élément HTML en Canvas
        //  html2canvas(element).then(canvas => {
            // Ajouter le Canvas au document PDF
         //   const imgData = canvas.toDataURL('image/png')
          //  doc.addImage(imgData, 'PNG', 10, 10)

            // Sauvegarder le document PDF
         //   doc.save('example.pdf')
        //  })
       // },

        listeCamion () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_camion.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesCamion = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },

        handleSelectChange(choix) {
          console.log(choix);
          const oumarItem = this.donneesZones.find(item => item.zone_livraison === choix);
          this.form.zone_facturation = oumarItem ? oumarItem.zone_facturation : null;
        },

      onSubmit() {
            this.donneesStats = [];


            fetch('https://socogetra.net/appi_sococit/albakoos_stats.php', {
              method: 'POST',
              body: JSON.stringify(this.formDate),
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(response => {
              console.log(response);
              this.don_Clients = response.dn_clients;
              console.log(this.don_Clients)
              return response.json();
            })
            .then(data => {
              this.donneesStats = data;
              this.don_Clients = data.dn_clients;
              console.log(this.don_Clients)
              this.$vs.loading.close();
              this.$notify.success({
                title: 'Dossier OK!',
                message: 'Dossier Crée',
                showClose: false
              });
            })
            .catch(error => {
              console.log(error);
              this.$vs.loading.close();
            });
        },

      clickFn() {
        this.$refs.statistic.suspend(this.stop);
        this.stop = !this.stop;
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      hilarity() {
        this.$notify({
          title: 'TRAVAIL DU JOUR TERMINE',
          message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
          duration: 0,
        });
      },

      getData() {
        const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA1REVBNjlBOUIxODE4RjI3RkIxNUQ2QzQ2QkI0NEU0IiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FjY291bnQubmF2aXMuY29tIiwibmJmIjoxNjg0MTAzMjY3LCJpYXQiOjE2ODQxMDMyNjcsImV4cCI6MTY4NDExMDQ2NywiYXVkIjpbImNvbXBhbnljbG91ZC1wdWJsaWNhcGkiLCJodHRwczovL2FjY291bnQubmF2aXMuY29tL3Jlc291cmNlcyJdLCJzY29wZSI6WyJjb21wYW55Y2xvdWQtcHVibGljYXBpIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJTbWFydEFjY2Vzcy1BcHAiLCJzdWIiOiI4NDVmNTIzYS0xMTBjLTQ4NjQtODVkNC1hYzE3NzlkN2YxZDAiLCJhdXRoX3RpbWUiOjE2ODQxMDMyNjYsImlkcCI6ImxvY2FsIiwiZW1haWwiOiJzYWtvbmVAc29jb2dldHJhLm5ldCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNha29uZUBzb2NvZ2V0cmEubmV0Iiwiam9pbmRhdGUiOiIwNS8wMy8yMDIzIDEwOjI1IiwicGljdHVyZSI6Imh0dHBzOi8vaW1hZ2VzLmFjY291bnQubmF2aXMuY29tL2VmZmVjNmUyLTIzNWQtNDNjMy05YmVmLTNlNTBkZDM0MmEwMS8xMDAucG5nIiwiZ2l2ZW5fbmFtZSI6IlNBS09ORSIsImZhbWlseV9uYW1lIjoiT3VtYXIiLCJzaWQiOiJFMkE2QzU4RkVDQzM0MDlFNjk4NDUzMURGNjc4RUMxQyJ9.rPA25RdckhXhl5sWBjVhI-C-8rIu3dX-w9zP2rk1Kwfpw8ED9LYV3J8d2sQknShx8vGTaZwSCiQLC55KNM1mi1b603uH8QEqz-Pb1Ql-wNWv_5NHIDdEG7d45G2KEX_CNjKBnNHS6agoatJbbjHQymdxmaudwQRqDJo9-mDXsG_ZUwD-vjYFQKvFqBzVJricJZiwrDAZXXIp9Yc2AvOVFsQs3DHXHk8GmY98q3-qt_Dfbr1UiJM-iejYzCO-dAa-YX2D78XK0UG9ejMcwKB1jBXpt0TgLMSHKIKcQSGVX5qPj_IHBB_dFgf3nNmrCz7mgoe4p1BCWHuItfk2iF7ccg'
        const url = 'https://api-smartaccess.cotedivoireterminal.com/smartaccess/api/gateway/vessel/visits'
        axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    mounted() {
      fetch('https://socogetra.net/appi_sococit/albakoos_stats.php?timestamp=' + new Date().getTime())
      .then(response => response.json())
      .then(data => {
        this.don_Clients = data.dn_clients;
        this.don_Camion = data.don_Camion;
        this.don_Camions = data.camion_litre;
        console.log(this.don_Camions)
      })
      .catch(error => {
        console.error(error);
      });
      this.getData();

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-input__inner::placeholder {
    color: #201f1f;
}

</style>
