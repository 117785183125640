<template>
    <div class="hello">
        <div v-if="isMobile">
          <Nav-bar style="width: 100%;"/>
          <vs-row>
            
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 400px; float: left; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 16px;"></vs-icon> Administration /</span> Dashboard
                </div>
                
                </vs-card>
              </div>
              
              <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 50px;">
  
                <vs-divider position="left"><img style="margin-top: 5px;" src="../assets/sogecit.png" width="100"></vs-divider>
                <el-card class="box-card" style="padding: -15px;  background-color: #fff; margin-bottom: 10px;">
                  <vs-row>
                    <vs-col vs-w="6">
                      <span>Sélection date</span>
                    </vs-col>
                    <vs-col vs-w="6">
                      <input v-on:input="onSubmit()" type="date" v-model="formDate.dateBen" style="float: right">
                    </vs-col>
                  </vs-row>
                </el-card>
                
                <vs-row>
  
                  <vs-col vs-w="12" style="">
                      <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57;">
                        <vs-icon icon="local_shipping" size="small" bg="#f0c1c5" style="color: #c22d39; font-size: 20px;" round></vs-icon> <span class="titre-zone">Total Livraison Import</span>
                            <vs-row class="space-zone">
                              <vs-col vs-w="12" style="display: grid;">
                                <vs-row class="space-zone">
                                  <vs-col vs-w="5.5" style="display: grid;">
                                    <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="local_shipping" color="white" bg="#409eff" round></vs-icon> <span class="prix-zone">{{ donneesStats.nb_voyages }} <span style="font-weight: normal;">voyages</span></span></span>
                                  </vs-col>
                                  <vs-col vs-w="6.5" style="display: grid;">
                                    <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone">0 CFA</span></span>
                                  </vs-col>
                                </vs-row>
                                
                              </vs-col>
                              
                            </vs-row>
                      </el-card>
                    </vs-col>
  
                    <vs-col vs-w="12" style=" margin-top: 15px">
                      <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57;">
                        <vs-icon icon="filter_alt" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone" v-if="donneesStats.dn_carb[0].total !== null && donneesStats.dn_carb[0].total !== undefined">Total Livraison Export</span>
                           <vs-row class="space-zone">
                              <vs-col vs-w="12" style="display: grid;">
                                <vs-row class="space-zone">
                                  <vs-col vs-w="5.5" style="display: grid;">
                                    <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="filter_alt" color="white" bg="#13ce66" round></vs-icon> <span class="prix-zone" v-if="donneesStats.dn_carb[0].litre !== null && donneesStats.dn_carb[0].litre !== undefined">{{ donneesStats.dn_carb[0].litre }} <span style="font-weight: normal;">litres</span></span></span>
                                  </vs-col>
                                  <vs-col vs-w="6.5" style="display: grid;">
                                    <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone" v-if="donneesStats.dn_carb[0].prix !== null && donneesStats.dn_carb[0].prix !== undefined">{{ formatMoney(donneesStats.dn_carb[0].prix) }}</span></span>
                                  </vs-col>
                                </vs-row>
                              </vs-col>
                            </vs-row>
                      </el-card>
                    </vs-col>
  
                    <vs-col vs-w="12" style=" margin-top: 15px">
                      <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57;">
                        <vs-icon icon="payments" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone">Total Dépenses</span>
                           <vs-row class="space-zone">
                              <vs-col vs-w="12" style="display: grid;">
                                <vs-row class="space-zone">
                                  <vs-col vs-w="5.5" style="display: grid;">
                                    <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#640064" round></vs-icon> <span class="prix-zone" v-if="donneesStats.dn_dep[0].total !== null && donneesStats.dn_dep[0].total !== undefined">{{ donneesStats.dn_dep[0].total }} <span style="font-weight: normal;">dépenses</span></span></span>
                                  </vs-col>
                                  <vs-col vs-w="6" style="display: grid;">
                                    <span class="sousTitre-zone"><vs-icon style=" padding: 3px; font-size: 15px" icon="payments" color="white" bg="#efa45b" round></vs-icon> <span style="color: #009eff;" class="prix-zone" v-if="donneesStats.dn_dep[0].prix !== null && donneesStats.dn_dep[0].prix !== undefined">{{ formatMoney(donneesStats.dn_dep[0].prix) }}</span></span>
                                  </vs-col>
                                </vs-row>
                              </vs-col>
                            </vs-row>
                      </el-card>
                    </vs-col>
                  </vs-row>
  
                  <vs-divider position="left">.</vs-divider>
                  
                  <el-card class="box-card" style="padding: -15px; border-radius: 20px;">
                  
                  <el-table
                    stripe
                    ref="multipleTable"
                    :data="donneesStats.tableau"
                    style="width: 100%; font-family: Helvetica;"
                    >
                    <el-table-column
                      type="selection"
                      width="55">
                      <img style="margin-top: 5px;" src="../assets/camionbenne.png" width="25">
                    </el-table-column>
                    <el-table-column
                      property="name"
                      label="CAMION">
                      <template slot-scope="scope">
                       
                        <el-link type="primary" @click="Borderau(scope.row)"> {{ scope.row.imatriculation }}</el-link>
                        
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="VOY"
                      property="nombre_de_bennes"
                      width="55">
                    </el-table-column>
                    
                    <el-table-column
                      label="CARB"
                      property="litre">
                      <template slot-scope="scope">
                       
                       <span>{{ scope.row.litre }} L</span>
                       
                     </template>
                    </el-table-column>
                    
                  </el-table>
                  </el-card>
  
                
                <vs-divider/>
                
              </div>
            </vs-col>
          </vs-row>
        </div>
        <div v-else class="screenUse">
          <Nav-bar style="width: 100%;"/>
          <vs-row>
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
             
              
              <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 10px;">
  
                  <vs-divider position="left">BILAN IMPORT <b></b></vs-divider>
                  <vs-row>
  
                     

                      <vs-col vs-w="2" style="padding-right: 10px;">
                        <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 10px solid #457c06;">
                          <vs-icon icon="local_shipping" size="small" bg="#009eff5c" style="color: #457c06; font-size: 20px;" round></vs-icon> <span class="titre-zone">Bilan <span style="color: blue; font-weight: bold">Hier</span></span>
                             <vs-row class="space-zone">
                                <vs-col vs-w="12" style="display: grid;">
                                  <vs-row class="space-zone">
                                    <vs-col style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">{{donneesResumer[0].livre_hier}}</span> Livré(s)
                                    </vs-col>
                                  </vs-row>
                                  <vs-row class="space-zone">
                                    <vs-col style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">{{donneesResumer[0].recuperation_hier}}</span> Récupéré(s)
                                    </vs-col>
                                  </vs-row>
                                </vs-col>
                              </vs-row>
                        </el-card>
                      </vs-col>

                      <vs-col vs-w="2" style="padding-right: 10px;">
                        <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 10px solid #7a7c06;">
                          <vs-icon icon="local_shipping" size="small" bg="#009eff5c" style="color: #7a7c06; font-size: 20px;" round></vs-icon> <span class="titre-zone">Bilan <span style="color: blue; font-weight: bold">Aujourd'hui</span></span>
                             <vs-row class="space-zone">
                                <vs-col vs-w="12" style="display: grid;">
                                  <vs-row class="space-zone">
                                    <vs-col style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">{{donneesResumer[0].livre_jour}}</span> Livré(s)
                                    </vs-col>
                                  </vs-row>
                                  <vs-row class="space-zone">
                                    <vs-col style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">{{donneesResumer[0].recuperation_jour}}</span> Récupéré(s)
                                    </vs-col>
                                  </vs-row>
                                </vs-col>
                              </vs-row>
                        </el-card>
                      </vs-col>

                      <vs-col vs-w="2" style="padding-right: 10px;">
                        <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 10px solid #7c6a06;">
                          <vs-icon icon="local_shipping" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone">BADT <span style="color: rgb(187, 156, 19); font-weight: bold">Pour Demain</span></span>
                             <vs-row class="space-zone">
                                <vs-col vs-w="12" style="display: grid;">
                                  <vs-row class="space-zone">
                                    <vs-col vs-w="4" style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">0</span>
                                    </vs-col>
                                  </vs-row>
                                </vs-col>
                              </vs-row>
                        </el-card>
                      </vs-col>

                      <vs-col vs-w="2" style="padding-right: 10px;">
                        <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 10px solid #7c0606;">
                          <vs-icon icon="local_shipping" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone">BADT <span style="color: rgb(187, 19, 19); font-weight: bold">Pour Aujourd'hui</span></span>
                             <vs-row class="space-zone">
                                <vs-col vs-w="12" style="display: grid;">
                                  <vs-row class="space-zone">
                                    <vs-col vs-w="4" style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">0</span>
                                    </vs-col>
                                  </vs-row>
                                </vs-col>
                              </vs-row>
                        </el-card>
                      </vs-col>

                      <vs-col vs-w="2" style="padding-right: 10px;">

                      </vs-col>
                      
                      <vs-col vs-w="2" style="padding-right: 10px;">
                        <el-card class="box-card" style="padding: -15px;  background-color: #ffffff57; border-bottom: 10px solid #706161;">
                          <vs-icon icon="local_shipping" size="small" bg="#009eff5c" style="color: #6285db; font-size: 20px;" round></vs-icon> <span class="titre-zone">ETAT <span style="color: rgb(187, 19, 19); font-weight: bold">SUR PARC</span></span>
                             <vs-row class="space-zone">
                                <vs-col vs-w="12" style="display: grid;">
                                  <vs-row class="space-zone">
                                    <vs-col vs-w="4" style="display: grid;">
                                      <span class="prix-zone" style="font-size: 25px">0</span>
                                    </vs-col>
                                  </vs-row>
                                </vs-col>
                              </vs-row>
                        </el-card>
                      </vs-col>
    
                     
                    </vs-row>
  
                    <vs-divider position="left">.</vs-divider>
  
  
  
                    <table border="1" ref="exportable_table" v-show="false">
                      <tbody >
                      <tr>
                          <th>ZONE</th>
                          <th>MODE</th>
                          <th>TYPE</th>
                          <th>NOMBRE</th>
                      </tr>
                      <tr v-for="(item, i) in donneesZones" :key="i">
                        <td >{{item.zone_facturation}}</td>
                          <td>{{item.mode}}</td>
                          <td>{{item.type_c}}</td>
                          <td>{{item.nombre}}</td>
                      </tr>
                      </tbody>
                  </table>
  
                  <div style="background-color: white;">
                    <vs-row>
                      
                      <vs-col vs-w="12" style="display: grid;">
                        
                        <div style="border: 1px solid #8080802e; margin: 10px; padding: 15px;">
                          <h3>LISTE DES DOSSIERS <el-link type="success" @click="ExportExcel('xls')"><img src="../assets/excel.png" width="14"> EXPORTER</el-link></h3>
                          <el-table
                            :data="donneesDossiers"
                            :row-class-name="tableRowClassName"
                            style="width: 100%; font-size: 13px;">
                            
                            <el-table-column
                              prop="name"
                              label="CLIENT">
                              <template slot-scope="scope">
                                  <b style="font-size: 22px; color: black" @click="Visite(scope.row.n_doc)">{{ scope.row.client }}</b>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="name"
                              label="ARMATEUR">
                              <template slot-scope="scope">
                                  <b style="font-size: 22px;">{{ scope.row.armateur }}</b>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="TC">
                              <template slot-scope="scope">
                                <vs-chip color="warning">
                                  <b style="color: white; letter-spacing: 2px; font-size: 22px; width: 30px">{{ scope.row.nombre }}</b>
                                </vs-chip>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="ZONE">
                              <template slot-scope="scope">
                                  <b style="font-size: 22px;">{{ scope.row.zone_livraison }}</b>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="BADT">
                              <template slot-scope="scope">
                                <vs-button color="primary" type="flat" @click="showClient(scope.row)" style="font-size: 22px;">{{ scope.row.badt }}</vs-button>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="LIVRAISON">
                              <template slot-scope="scope">
                                <div v-if="scope.row.livre == scope.row.nombre">
                                    <span style="font-size: 20px"><vs-icon style=" padding: 3px; font-size: 22px" icon="done_all" color="white" bg="#13ce66" round></vs-icon> <b style="font-size: 22px">LIVRE</b></span>
                                   
                                </div>
                                <div v-else>
                                    <span style="font-size: 20px; color: blue" v-if="calculerJoursRestants(scope.row.badt) < 0"><b style="font-size: 22px"> {{ calculerJoursRestants(scope.row.badt) * -1 }} jrs passé(s)</b></span>
                                    <span style="font-size: 20px; color: red" v-if="calculerJoursRestants(scope.row.badt) == 0"> <b style="font-size: 22px">AUJOURD'HUI</b></span>
                                    <span style="font-size: 20px; color: orange" v-if="calculerJoursRestants(scope.row.badt) > 0 && calculerJoursRestants(scope.row.badt) < 3"><b style="font-size: 22px">{{ calculerJoursRestants(scope.row.badt) }} Jours restant</b></span>
                                    <span style="font-size: 20px; color: green" v-if="calculerJoursRestants(scope.row.badt) > 2">{{ calculerJoursRestants(scope.row.badt) }} Jours restant</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="RECUPERATION">
                              <template slot-scope="scope">
                                <div v-if="scope.row.recupere == scope.row.nombre">
                                    <span style="font-size: 20px"><vs-icon style=" padding: 3px; font-size: 22px" icon="done_all" color="white" bg="#13ce66" round></vs-icon> <b style="font-size: 22px">RECUPERE</b></span>
                                   
                                </div>
                                <div v-else>
                                    <span style="font-size: 20px; color: blue" v-if="calculerJoursRestants(scope.row.badt) < 0"><b style="font-size: 22px">Passé de {{ calculerJoursRestants(scope.row.badt) * -1 }} jrs</b></span>
                                    <span style="font-size: 20px; color: red" v-if="calculerJoursRestants(scope.row.badt) == 0"> <b style="font-size: 22px">AUJOURD'HUI</b></span>
                                    <span style="font-size: 20px; color: orange" v-if="calculerJoursRestants(scope.row.badt) > 0 && calculerJoursRestants(scope.row.badt) < 3"><b style="font-size: 22px">{{ calculerJoursRestants(scope.row.badt) }} Jours restant</b></span>
                                    <span style="font-size: 20px; color: green" v-if="calculerJoursRestants(scope.row.badt) > 2">{{ calculerJoursRestants(scope.row.badt) }} Jours restant</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="LIVRE">
                              <template slot-scope="scope">
                                <vs-chip color="success">
                                  <b style="color: white; letter-spacing: 2px; font-size: 22px; width: 30px">{{ scope.row.livre }}</b>
                                </vs-chip>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="address"
                              label="RECUPE">
                              <template slot-scope="scope">
                                <vs-chip color="primary">
                                  <b style="color: white; letter-spacing: 2px; font-size: 22px; width: 30px">{{ scope.row.recupere }}</b>
                                </vs-chip>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>
                  
                <vs-divider/>
                
              </div>
  
            </vs-col>
          </vs-row>
  
          <vs-popup classContent="popup-example"  title="BILAN CLIENT" :active.sync="popupActivo2">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Client</label>
                  {{ ClientChoisi.client }}
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Produit</label>
                  {{ ClientChoisi.produit }}
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Bons</label>
                  {{ ClientChoisi.total_enregistrements }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Poids</label>
                  {{ ClientChoisi.total_poids }}
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Prix Unitaire</label>
                  <el-input style="width: 100%;" v-model="prixUnit" placeholder="Prix">
                    </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Prix total</label>
                  {{ ClientChoisi.total_poids * prixUnit }}
                </vs-col>
              </vs-row>
              
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="generateReport()" color="primary" type="filled">Enregistrer le dossier</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>
  
          <vs-popup classContent="popup-example"  title="Bordereau de livraison" :active.sync="activePrompt9">
            
            <center style="width: 21cm;">
              
              <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="800"
              filename="hee hee"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="PORTRAIT"
              pdf-content-width="800px"
  
              @hasStartedGeneration="hasStartedGeneration()"
              @hasGenerated="hasGenerated($event)"
              ref="html2Pdf"
          >
              <section slot="pdf-content">
                <div>
                  <div style="width: 28cm;  margin-left: .5cm; margin-top: 20px;" id="html-to-pdf">
                    <div style="width: 100%; padding: 10px">
                      <div style="float: left; display: block;">
                      </div>
                      <div style="float: left; padding: 12px;">
                        <span style="font-weight: bold; font-size: 19px; font-family: 'Nunito Sans', sans-serif;">BILAN MENSUEL - <span><b> </b></span><span style="color: red; font-size: 18px"></span></span>
                      </div>
                    </div>
                  .
                    <h2></h2>
                    <vs-divider/>
                    <vs-row>
                      <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="3">
                        <el-input placeholder="Entrez quelque chose"  v-model="ClientChoisi.client">
                          <template slot="prepend">CLIENT</template>
                        </el-input>
                      </vs-col>
                      <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="5">
                        <el-input placeholder="Entrez quelque chose" v-model="ClientChoisi.produit">
                          <template slot="prepend">PRODUIT</template>
                        </el-input>
                      </vs-col>
                    </vs-row>
                    <vs-row>
                      <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="3">
                        <el-input placeholder="Entrez quelque chose"  v-model="ClientChoisi.total_enregistrements">
                          <template slot="prepend">Bons / Voyages</template>
                        </el-input>
                      </vs-col>
                      <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="5">
                        <el-input placeholder="Entrez quelque chose" v-model="ClientChoisi.total_poids">
                          <template slot="prepend">Poids</template>
                        </el-input>
                      </vs-col>
                    </vs-row>
                    <vs-row>
                      <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="3">
                        <el-input placeholder="Entrez quelque chose"  v-model="prixUnit">
                          <template slot="prepend">Prix Unitaire</template>
                        </el-input>
                      </vs-col>
                      <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="5">
                        <el-input placeholder="Entrez quelque chose" :value="ClientChoisi.total_poids * prixUnit">
                          <template slot="prepend">Prix Total</template>
                        </el-input>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                  
              </section>
          </vue-html2pdf>
             
                    
            </center>
                  
           
          </vs-popup>
        </div>
       
    </div>
  </template>
  
  <script>

  import axios from 'axios';
  import NavBar from '@/components/Navbar.vue'
  export default {
    name: 'HelloWorld',
    components: {
      NavBar,
    },
    data() {
      return {
        dateDonnee: new Date('2023-06-29'),
        dateActuelle: new Date(),
        counter: false,
        prixUnit: 0,
        dateSearch: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
          ],
        isMobile: false,
        popupActivo2:false,
        popupActivo3:false,
        activePrompt9:false,
        select1:3,
        options1:[
          {text:'IT',value:0},
          {text:'Blade Runner',value:2},
          {text:'Thor Ragnarok',value:3},
        ],
        formDate: {
          dateBen: ''
        },
        checkTrue: true,
        donneesClient: null,
        donneesCliProd: null,
        checkFalse: false,
        totalVoyages:0,
        donnees: null,
        don_Clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Clients2: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Camion: null,
        donneesStats: {
          dn_carb: [{"total":"0","litre":"0","prix":"0"}],
          dn_dep: [{"total":"0","prix":"0"}],
          dn_voyages: [{"chauffeur":"","client":"","dossier":"0","id_ben":"0","lieu_chargement":"0","lieu_dechargement":"","n_bl":"","n_matricule":"","observation":"0","par":"0","poids":"0","prix":"0","produit":"0"}],
          dn_clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
          nb_carb: 0,
          nb_dep: 0,
          nb_voyages: 0,
          tableau: [{"camion":"0","chauffeur":"0","date_carb":"0","details":"0","id_camion":"0","id_carburant":"0","imatriculation":"0","litre":"0","marque":"0","nombre_de_bennes":"0","prix":"0"}],
        },
        donneesStatsMois: null,
        donneesStatsSemaine: null,
        donneesArmateurs: null,
        donneesSheet: null,
        elementChoisi: null,
        donneesZones: null,
        donneesCamion: null,
        ClientChoisi: {
          client: '',
          produit: '',
          total_enregistrements: '',
          total_poids: ''
        },
        donneesChauf: null,
        donneesDossiers: null,
        deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
        like: true,
          value1: '',
          value2: '',
          title: 'Growth this year',
        activeName: 'first',
        chartData: {
          labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
          datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
        },
        chartOptions: {
          responsive: true
        },
        donneesResumer: [
          {
            livre_jour: 0,
            livre_hier: 0,
            recuperation_jour: 0,
            recuperation_hier: 0,
          }
        ],
        form: {
          date_recept: '',
          n_doc: '',
          n_conteneur: '',
          type_c: '',
          nbre_tcs: 1,
          n_declaration: '',
          bl: '',
          statut: '',
          armateur: '',
          client: '',
          camion: '',
          chauffeur: '',
          zone_livraison: '',
          zone_facturation: '',
          badt: '',
          do: '',
          sortie_port: '',
          sejour: '',
          date_requete: '',
          date_recup: '',
          entree_parc: '',
          transitaire: '',
          observation: ''
          },
          options: [{
            value: 'Option1',
            label: 'Option1'
          }],
          type_cont: [{
            value: "20 'OPEN",
            label: "20 'OPEN"
          }],
          armateur: [{
            value: "Evergreen",
            label: "Evergreen"
          }],
        tableData: [{
            date: '2016-05-03',
            name: 'TRAORE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }],
      }
    },
    methods: {
        tableRowClassName({row}) {
            if (this.calculerJoursRestants(row.badt) > 0 && this.calculerJoursRestants(row.badt) < 3) {
            return 'warning-row';
            } else if (this.calculerJoursRestants(row.badt) <= 0 && row.livre != row.nombre) {
            return 'danger-row';
            }
            return '';
        },
        calculerJoursRestants(a) {
            this.dateDonnee = new Date(a);
            const differenceEnMillisecondes = this.dateDonnee - this.dateActuelle;
            const differenceEnJours = Math.round(differenceEnMillisecondes / (1000 * 60 * 60 * 24));
            return differenceEnJours;
        },
      choixPeriode(a) {
        this.periode = a;
        console.log(this.periode);
      },
  
      Visite(id) {
        this.$router.push({ name: 'AD_Details', params: { element: id } });
      },
  
      listeDossiers () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sogecit.net/appi_socogetra/soco_bilan_dossier.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesDossiers = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
          Resumer () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sogecit.net/appi_socogetra/stat_bilan.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesResumer = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
        StatsMonth () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://sogecit.net/appi_sococit/albakoos_stats_month.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesStatsMois = response.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        StatsWeek () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://sogecit.net/appi_sococit/Albakoos_stats_week.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donneesStatsSemaine = response.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        listeDossier () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://sogecit.net/appi_sococit/albakoos_dossier_sbtl.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donnees = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
      
      },
  
      mounted() {
        fetch('https://sogecit.net/appi_sococit/albakoos_stats.php?timestamp=' + new Date().getTime())
        .then(response => response.json())
        .then(data => {
          this.don_Clients = data.dn_clients;
          this.don_Camion = data.don_Camion;
          console.log(data)
        })
        .catch(error => {
          console.error(error);
        });
        this.listeDossiers();
        this.Resumer();
  
      },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .danger-row {
    background: #f7bdc1;
  }
</style>
  