<template>
    <div class="hello">
        <div style="display: flex; height: calc(100vh - 48px);">
          <div style="width: 43px; background-color: #f0f0f0; border-right: 1px solid #ebeaea">
            <router-link to="/bennes_dash"> <vs-button style="margin: 50px -22px;" icon="home" color="#C32D39" type="line" id="rotate">Bennes</vs-button> </router-link>
            <router-link to="/conteneurs_dash"> <vs-button style="margin: 50px -34px;" icon="local_shipping" color="#199C19" type="line" id="rotate">Conteneurs</vs-button> </router-link>
            <router-link to="/r_humaine/employes"> <vs-button style="margin: 50px -34px;" icon="groups" color="#447799" type="line" id="rotate">R_Humaine</vs-button> </router-link>
            <router-link to="/cloud"> <vs-button style="margin: 50px -21px;" icon="groups" color="#8A71D9" type="line" id="rotate">Fichiers</vs-button> </router-link>
          </div>
            <div style="background-color: #fafafa; width: calc(100% - 43px);">
                <vs-sidebar static-position default-index="1" color="danger" class="sidebarx" spacer style="z-index: 100; width: 100%; height: calc(100vh - 48px); background: #fafafa;" v-model="active">

                    <div class="header-sidebar" slot="header" style="display: grid;">
                        <center><vs-avatar  size="70px" :src="users.photo"/></center>

                        <div>
                            <h5 style="text-align: center;">
                              {{users.name}} {{users.prenom}}
                            </h5>
                            <p style="text-align: center; font-size: 12px;">
                              {{users.titre}}
                            </p>
                        </div>
                        

                    </div>
                    <div style="overflow: auto; height: 70vh;">
                   

                    <vs-divider position="center" class="sideItem">
                    Bennes
                    </vs-divider>

                    <vs-sidebar-item to="/bennes_dash" index="1" icon="dashboard" class="sideItem">
                    Dashboard Benne
                    </vs-sidebar-item>

                    <vs-sidebar-item to="/bennes_client" index="2" icon="account_circle" class="sideItem">
                    Clients
                    </vs-sidebar-item>

                    <vs-sidebar-item to="/bennes_list" index="3" icon="bookmark" class="sideItem">
                    Bons
                    </vs-sidebar-item>

                    <vs-sidebar-item to="/bennes_chauffeur" index="4" icon="person" class="sideItem">
                    Chauffeurs
                    </vs-sidebar-item>

                    <vs-sidebar-item to="/bennes_camion" index="5" icon="local_shipping" class="sideItem">
                    Camions
                    </vs-sidebar-item>
                  </div>

                    <div class="footer-sidebar" slot="footer">
                        <vs-button icon="reply" color="danger" type="flat" @click="deconnexion()">Déconnexion</vs-button>
                        <vs-button icon="settings" color="primary" type="border"></vs-button>
                    </div>

                </vs-sidebar>
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
  export default {
    name: 'SideBar',
    computed: {
    users() {
      return this.$store.state.users;
        }
      },
    data() {
      return {
        affiche: false,
        active: true,
        value2: true,
        user: {
        nom : 'a',
        number: '',
        email : '',
        photo : '',
        titre : '',
        statut : '',
        droit : '',
        lieu : '',
        id : null,
      },
      }
    },
    methods:{
    openLoading(){
      this.$vs.loading()
      setTimeout( ()=> {
        this.$vs.loading.close()
      }, 2000);
    },
    deconnexion(){
      this.$vs.loading()
      
      this.$store.commit('Connexion', this.user);
            this.$router.push({ path: '/about' });
              setTimeout( ()=> {
        this.$vs.loading.close()
      }, 2000);
    }
  },
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
  